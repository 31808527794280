import { LogoFlat } from 'graphics/LogoFlat';
import { TheIsland } from 'graphics/TheIsland';
import { Logo } from 'components/Logo';
import { Contacts } from 'partials/Contacts';
import { NavLink } from 'react-router-dom';

const FooterCopyright = () => (
  <div className="flex flex-col border-t border-gray-600 pt-5 md:h-60 md:flex-col">
    <div className="flex flex-col md:flex-col">
      <div className="w-full text-sm text-gray-200">
        <NavLink to="/">
          <Logo
            icon={
              <LogoFlat
                className="h-14 w-auto"
              />
            }
            name={
              <TheIsland
                className="mr-1 h-14 w-auto"
              />
            }
          />
        </NavLink>
      </div>
      <div className="w-full text-sm text-gray-200">
        <Contacts />
      </div>
    </div>
    <div className="text-center text-sm text-gray-200">
      © {new Date().getFullYear()} Tutti i diritti riservati.
      <br />
      THE ISLAND DI DONATI NICOLA
      <br />
      Cod. Fisc. DNTNCL94L06F656R P. IVA 02463830519
    </div>
  </div>
);

export { FooterCopyright };
