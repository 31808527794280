import type { ReactNode } from 'react';

type ISectionProps = {
  title?: ReactNode;
  children: ReactNode;
  fullScreen?: boolean;
};

const Section = (props: ISectionProps) => (
  <div
    className={`mx-auto ${
      props.fullScreen ? 'w-screen' : 'max-w-screen-xl px-5'
    }  py-6`}
  >
    {props.title && <div className="mb-6 text-2xl font-bold">{props.title}</div>}

    {props.children}
  </div>
);

export { Section };
