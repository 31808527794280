import { GradientText } from 'components/GradientText';
import { Section } from 'components/Section';

const Contacts = () => (
  <Section>
    <div className="w-auto">
      <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
        <div className="text-center">
          <h3 className="uppercase">
            <GradientText>Sede operativa</GradientText>
          </h3>
          <a
            className="hover:underline"
            href="https://maps.app.goo.gl/j59wMTK5R8JJSRCv7"
          >
            Via Urbinese 123, 52020, Castelfranco Piandisc� AR
          </a>
        </div>
        <div className="text-center">
          <h3 className="uppercase">
            <GradientText>Telefono</GradientText>
          </h3>
          <a className="hover:underline" href="tel:+393913307552">
            +39 3913307552
          </a>
        </div>
        <div className="text-center">
          <h3 className="uppercase">
            <GradientText>Email</GradientText>
          </h3>
          <a className="hover:underline" href="mailto:donati@theisland.tech">
            donati@theisland.tech
          </a>
        </div>
      </div>
    </div>
  </Section>
);

export { Contacts };
