import type { ReactNode } from 'react';

type ILogoProps = {
  icon: ReactNode;
  name: ReactNode;
};

const Logo = (props: ILogoProps) => (
  <div className="flex w-full justify-center">
    {props.icon}

    {props.name}
  </div>
);

export { Logo };
