import { GradientText } from "components/GradientText";
import { Section } from "components/Section";
import { useForm, SubmitHandler } from "react-hook-form";
import axios from "axios";
import toast from "react-hot-toast";

interface IFormInput {
  author: string;
  title: string;
  description: string;
  slug: number;
  content: number;
  imageSource: string;
  imageAlt: string;
}

export function WritePost() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = (data, e) => {
    e?.preventDefault();
    console.log(data);

    const promise = axios.post("/api/blog/post", data);

    toast.promise(promise, {
      loading: "Pubblicazione in corso...",
      success: "Pubblicazione eseguita con successo!",
      error: "Errore durante la pubblicazione.",
    });
  };

  return (
    <>
      <h1 className="text-center text-3xl font-bold">Nuovo post</h1>
      <Section>
        <div className="h-fit max-w-6xl md:p-12" id="form">
          <h2 className="mb-4 text-2xl font-bold">
            Pronti a Navigare insieme verso il{" "}
            <GradientText>Successo</GradientText> Online? Contattaci ora!
          </h2>
          <form onSubmit={handleSubmit(onSubmit)} id="contactForm" noValidate>
            <div className="mb-6">
              <div className="mx-0 mb-1 sm:mb-4">
                <div className="mx-0 mb-1 sm:mb-4">
                  <label
                    htmlFor="author"
                    className="pb-1 text-xs uppercase tracking-wider"
                  ></label>
                  <input
                    {...register("author", {
                      required: "Devi inserire il nome dell'autore",
                    })}
                    type="text"
                    id="author"
                    placeholder="Nome dell'autore"
                    className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md placeholder-gray-600 text-gray-900 sm:mb-0"
                    name="author"
                  ></input>
                  {errors.author?.message && (
                    <strong className="text-red-400">
                      {errors.author.message}
                    </strong>
                  )}
                </div>
                <div className="mx-0 mb-1 sm:mb-4">
                  <label
                    htmlFor="slug"
                    className="pb-1 text-xs uppercase tracking-wider"
                  ></label>
                  <input
                    {...register("slug", {
                      required: "Devi inserire lo slug del post",
                    })}
                    type="text"
                    id="slug"
                    placeholder="Slug del post"
                    className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md placeholder-gray-600 text-gray-900 sm:mb-0"
                    name="slug"
                  ></input>
                  {errors.slug?.message && (
                    <strong className="text-red-400">
                      {errors.slug.message}
                    </strong>
                  )}
                </div>
                <div className="mx-0 mb-1 sm:mb-4">
                  <label
                    htmlFor="title"
                    className="pb-1 text-xs uppercase tracking-wider"
                  ></label>
                  <input
                    {...register("title", {
                      required: "Devi inserire il titolo",
                    })}
                    type="text"
                    id="title"
                    placeholder="Titolo del post"
                    className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md placeholder-gray-600 text-gray-900 sm:mb-0"
                    name="title"
                  ></input>
                  {errors.title?.message && (
                    <strong className="text-red-400">
                      {errors.title.message}
                    </strong>
                  )}
                </div>
                <div className="mx-0 mb-1 sm:mb-4">
                  <label
                    htmlFor="description"
                    className="pb-1 text-xs uppercase tracking-wider"
                  ></label>
                  <input
                    {...register("description", {
                      required: "Devi inserire la descrizione",
                    })}
                    type="text"
                    id="description"
                    placeholder="Descrizione del post"
                    className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md placeholder-gray-600 text-gray-900 sm:mb-0"
                    name="description"
                  ></input>
                  {errors.description?.message && (
                    <strong className="text-red-400">
                      {errors.description.message}
                    </strong>
                  )}
                </div>
                <div className="mx-0 mb-1 sm:mb-4">
                  <label
                    htmlFor="imageSource"
                    className="pb-1 text-xs uppercase tracking-wider"
                  ></label>
                  <input
                    {...register("imageSource", {
                      required: "Devi inserire l'url di una immagine",
                    })}
                    type="text"
                    id="imageSource"
                    placeholder="Immagine del post"
                    className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md placeholder-gray-600 text-gray-900 sm:mb-0"
                    name="imageSource"
                  ></input>
                  {errors.imageSource?.message && (
                    <strong className="text-red-400">
                      {errors.imageSource.message}
                    </strong>
                  )}
                </div>
                <div className="mx-0 mb-1 sm:mb-4">
                  <label
                    htmlFor="imageAlt"
                    className="pb-1 text-xs uppercase tracking-wider"
                  ></label>
                  <input
                    {...register("imageAlt", {
                      required: "Devi inserire l'alt dell'immagine",
                    })}
                    type="text"
                    id="imageAlt"
                    placeholder="Alt dell'immagine"
                    className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md placeholder-gray-600 text-gray-900 sm:mb-0"
                    name="imageAlt"
                  ></input>
                  {errors.imageAlt?.message && (
                    <strong className="text-red-400">
                      {errors.imageAlt.message}
                    </strong>
                  )}
                </div>
              </div>
              <div className="mx-0 mb-1 sm:mb-4">
                <label
                  htmlFor="content"
                  className="pb-1 text-xs uppercase tracking-wider"
                ></label>
                <textarea
                  {...register("content", {
                    required: "Devi inserire il messaggio",
                  })}
                  id="content"
                  name="content"
                  cols={30}
                  rows={5}
                  placeholder="Contenuto del post..."
                  className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md placeholder-gray-600 text-gray-900 sm:mb-0"
                ></textarea>
                {errors.content?.message && (
                  <strong className="text-red-400">
                    {errors.content.message}
                  </strong>
                )}
              </div>
            </div>
            <div className="text-center">
              <button
                className="w-full shrink-0 rounded-lg bg-[#57cd38] px-3 py-1 text-3xl font-medium uppercase text-black transition delay-100 duration-150 ease-in-out hover:-translate-y-1  hover:scale-110"
                type="submit"
              >
                Pubblica post
              </button>
            </div>
          </form>
        </div>
      </Section>
    </>
  );
}
