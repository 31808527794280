import type { ReactNode } from 'react';

type IGradientTextProps = {
  children: ReactNode;
};

const GradientText = (props: IGradientTextProps) => (
  <span className="bg-clip-text text-[#57cd38]">{props.children}</span>
);

export { GradientText };
