import { LogoFlat } from "graphics/LogoFlat";
import { TheIsland } from "graphics/TheIsland";
import { Logo } from "components/Logo";
import { Section } from "components/Section";
import { NavLink } from "react-router-dom";

const Navbar = () => (
  <Section>
    <div className="flex flex-col gap-y-3 md:flex-row md:items-center md:justify-between">
      <NavLink to="/" aria-label="THE ISLAND OF TECHNOLOGY">
        <Logo
          icon={<LogoFlat className="mr-1 h-14 w-auto" />}
          name={<TheIsland className="mr-1 h-14 w-auto" />}
        />
      </NavLink>

      <nav>
        <ul className="flex justify-center gap-x-6 font-medium text-gray-200">
          <li className="shrink-0 text-3xl font-medium uppercase text-white transition delay-100 duration-150 ease-in-out hover:-translate-y-1 hover:scale-110 hover:underline">
            <NavLink
              className={({ isActive }) => (isActive ? "text-[#57cd38]" : "")}
              to="/"
            >
              home
            </NavLink>
          </li>
          <li className="shrink-0 text-3xl font-medium uppercase text-white transition delay-100 duration-150 ease-in-out hover:-translate-y-1 hover:scale-110 hover:underline">
            <NavLink
              className={({ isActive }) => (isActive ? "text-[#57cd38]" : "")}
              to="/blog"
            >
              Blog
            </NavLink>
          </li>
          <li className="shrink-0 text-3xl font-medium uppercase text-white transition delay-100 duration-150 ease-in-out hover:-translate-y-1 hover:scale-110 hover:underline">
            <NavLink
              className={({ isActive }) => (isActive ? "text-[#57cd38]" : "")}
              to="/contacts"
            >
              Contatti
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  </Section>
);

export { Navbar };
