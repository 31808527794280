import { FooterCopyright } from 'components/FooterCopyright';
import { Section } from 'components/Section';

const Footer = () => (
  <Section>
    <FooterCopyright />
  </Section>
);

export { Footer };
