import { LogoFlat } from "graphics/LogoFlat"
import { Logo } from "./Logo"
import { TheIsland } from "graphics/TheIsland"

const Preloader = () => {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        background: '#222222'
      }}
    >
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden'
        }}
      >
        <div className="scale-up-center">
            <Logo
            icon={
              <LogoFlat
                className="h-14 w-auto"
              />
            }
            name={
              <TheIsland
                className="mr-1 h-14 w-auto"
              />
            }
          />
        </div>
      </div>
    </div>
  )
}

export default Preloader
