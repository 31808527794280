type ITheIslandProps = {
  className?: string | undefined;
};

export const TheIsland = (props: ITheIslandProps) => (
  <svg
   width="1024"
   height="201.73"
   version="1.1"
   viewBox="0 0 1024 201.73"
   id="svg12"
   xmlns="http://www.w3.org/2000/svg"
   className={props.className}>
  <title>The Island</title>
  <g
     fill="#57cd38">
    <path
       d="m 37.626924,24.062238 v 78.301692 q 0,5.04679 3.517459,8.56425 3.593925,3.51746 8.564247,3.51746 4.970323,0 8.487782,-3.51746 3.517459,-3.51746 3.517459,-8.56425 V 24.062238 h 25.616277 q 4.970323,0 8.564248,-3.517459 3.517459,-3.517459 3.517459,-8.487781 0,-4.9703225 -3.517459,-8.4877815 -3.593925,-3.59392543 -8.564248,-3.59392543 H 12.087113 q -5.0467892,0 -8.5642481,3.59392543 -3.51745899,3.517459 -3.51745899,8.4877815 0,4.970322 3.51745899,8.487781 3.5174589,3.517459 8.5642481,3.517459 z"
       id="path405" />
    <path
       d="m 126.55734,114.44564 q 4.97032,0 8.48778,-3.51746 3.51746,-3.51746 3.51746,-8.56425 V 69.253939 h 51.23255 v 33.109991 q 0,5.04679 3.51746,8.56425 3.51746,3.51746 8.48778,3.51746 4.97033,0 8.56425,-3.51746 3.51746,-3.51746 3.51746,-8.56425 V 12.056998 q 0,-4.9703225 -3.51746,-8.4877815 -3.59392,-3.59392543 -8.56425,-3.59392543 -4.97032,0 -8.48778,3.59392543 -3.51746,3.517459 -3.51746,8.4877815 V 45.166992 H 138.56258 V 12.056998 q 0,-4.9703225 -3.51746,-8.4877815 -3.51746,-3.59392543 -8.48778,-3.59392543 -5.04679,0 -8.56425,3.59392543 -3.51746,3.517459 -3.51746,8.4877815 v 90.306932 q 0,5.04679 3.51746,8.56425 3.51746,3.51746 8.56425,3.51746 z"
       id="path407" />
    <path
       d="m 325.29389,114.44564 q 4.97032,0 8.56425,-3.51746 3.51746,-3.51746 3.51746,-8.56425 0,-4.970319 -3.51746,-8.487778 -3.59393,-3.517459 -8.56425,-3.517459 h -63.2378 V 69.253939 h 48.1739 q 4.97032,0 8.56425,-3.517459 3.51746,-3.517458 3.51746,-8.487781 0,-5.046789 -3.51746,-8.564248 -3.59393,-3.517459 -8.56425,-3.517459 h -48.1739 V 24.062238 h 63.2378 q 4.97032,0 8.56425,-3.517459 3.51746,-3.517459 3.51746,-8.487781 0,-4.9703225 -3.51746,-8.4877815 -3.59393,-3.59392543 -8.56425,-3.59392543 h -75.24304 q -5.04679,0 -8.56424,3.59392543 -3.51746,3.517459 -3.51746,8.4877815 v 90.306932 q 0,5.04679 3.51746,8.56425 3.51745,3.51746 8.56424,3.51746 z"
       id="path409" />
    <path
       d="m 433.72343,114.44564 q 4.97032,0 8.48778,-3.51746 3.51746,-3.51746 3.51746,-8.56425 V 12.056998 q 0,-4.9703225 -3.51746,-8.4877815 -3.51746,-3.59392543 -8.48778,-3.59392543 -5.04679,0 -8.56425,3.59392543 -3.51746,3.517459 -3.51746,8.4877815 v 90.306932 q 0,5.04679 3.51746,8.56425 3.51746,3.51746 8.56425,3.51746 z"
       id="path411" />
    <path
       d="m 475.93297,90.358693 q -5.04679,0 -8.56424,3.517459 -3.51746,3.517459 -3.51746,8.487778 0,5.04679 3.51746,8.56425 3.51745,3.51746 8.56424,3.51746 h 52.68542 q 14.29924,0 24.46928,-10.17004 10.17005,-10.170048 10.17005,-24.469284 0,-14.299235 -10.17005,-24.469279 -10.17004,-10.170045 -24.46928,-10.170045 h -30.1278 q -4.35859,0 -7.41725,-3.05866 -3.13513,-3.135126 -3.13513,-7.493717 0,-4.35859 3.13513,-7.41725 3.05866,-3.135127 7.41725,-3.135127 h 52.68542 q 4.97032,0 8.56425,-3.517459 3.51746,-3.517459 3.51746,-8.487781 0,-4.9703225 -3.51746,-8.4877815 -3.59393,-3.59392543 -8.56425,-3.59392543 h -52.68542 q -14.29923,0 -24.46928,10.17004393 -10.17004,10.170045 -10.17004,24.46928 0,14.375702 10.17004,24.46928 10.17005,10.170044 24.46928,10.170044 h 30.1278 q 4.35859,0 7.41725,3.135127 3.13513,3.05866 3.13513,7.41725 0,4.358591 -3.13513,7.493717 -3.05866,3.05866 -7.41725,3.05866 z"
       id="path413" />
    <path
       d="m 593.3856,114.44564 q -5.04679,0 -8.56425,-3.51746 -3.51746,-3.51746 -3.51746,-8.56425 V 12.056998 q 0,-4.9703225 3.51746,-8.4877815 3.51746,-3.59392543 8.56425,-3.59392543 4.97032,0 8.48778,3.59392543 3.51746,3.517459 3.51746,8.4877815 v 78.301695 h 48.1739 q 4.97032,0 8.56424,3.517459 3.51746,3.517459 3.51746,8.487778 0,5.04679 -3.51746,8.56425 -3.59392,3.51746 -8.56424,3.51746 z"
       id="path415" />
    <path
       d="m 728.80769,38.973206 14.37571,28.751403 h -28.75141 z m -50.54435,74.172504 q 4.43505,2.21753 9.17598,0.61173 4.74092,-1.52933 6.95845,-6.04085 l 7.95252,-15.905033 h 52.83835 l 8.02898,15.905033 q 1.52933,3.13513 4.51152,4.97032 2.90573,1.75873 6.27026,1.75873 2.82926,0 5.35265,-1.29993 4.43506,-2.21753 6.04085,-6.95845 1.6058,-4.74092 -0.68819,-9.175981 L 739.58947,6.6278765 q -1.52933,-3.05866 -4.43506,-4.8938559 -2.90572,-1.75872953 -6.34672,-1.75872953 -3.44099,0 -6.34672,1.75872953 -2.90572,1.8351959 -4.43505,4.8938559 l -45.1917,90.3834025 q -2.21753,4.435061 -0.61174,9.175981 1.52933,4.74092 6.04086,6.95845 z"
       id="path417" />
    <path
       d="m 809.09754,0.73995606 q -3.59393,1.29993054 -5.73499,4.35859044 -2.21753,3.1351265 -2.21753,6.9584515 v 90.306932 q 0,5.04679 3.51746,8.56425 3.51746,3.51746 8.56425,3.51746 4.97032,0 8.48778,-3.51746 3.51746,-3.51746 3.51746,-8.56425 V 45.319925 l 53.98535,64.767125 q 3.59392,4.35859 9.25244,4.35859 2.14106,0 4.12919,-0.6882 3.59393,-1.29993 5.73499,-4.43505 2.21753,-3.13513 2.21753,-6.95846 V 12.056998 q 0,-4.9703225 -3.51746,-8.4877815 -3.59393,-3.59392543 -8.56425,-3.59392543 -4.97032,0 -8.48778,3.59392543 -3.51746,3.517459 -3.51746,8.4877815 V 69.101006 L 822.47917,4.3338815 q -2.44693,-2.9057269 -6.11732,-3.89979143 -3.67039,-0.9940645 -7.26431,0.30586599 z"
       id="path419" />
    <path
       d="m 948.7255,24.062238 h 18.04609 q 13.76397,0 23.47522,9.787712 9.71124,9.711246 9.71124,23.398749 0,13.687503 -9.71124,23.398749 -9.71125,9.711245 -23.47522,9.711245 H 948.7255 Z m 18.04609,90.383402 q 23.70462,0 40.45081,-16.746163 16.8226,-16.82263 16.8226,-40.450778 0,-23.704615 -16.8226,-40.450778 -16.74619,-16.82262993 -40.45081,-16.82262993 h -30.05133 q -5.04679,0 -8.56425,3.59392543 -3.51746,3.517459 -3.51746,8.4877815 v 90.306932 q 0,5.04679 3.51746,8.56425 3.51746,3.51746 8.56425,3.51746 z"
       id="path421" />
  </g>
  <g
     fill="#57cd38">
    <path
       d="m 261.99862,179.47019 q 0,5.32285 -3.77655,9.0994 -3.80629,3.77655 -9.12914,3.77655 -5.32285,0 -9.0994,-3.77655 -3.77655,-3.77655 -3.77655,-9.0994 0,-5.32285 3.77655,-9.0994 3.77655,-3.80629 9.0994,-3.80629 5.32285,0 9.12914,3.80629 3.77655,3.77655 3.77655,9.0994 z m -35.14867,0 q 0,9.18861 6.51232,15.73066 6.54205,6.51232 15.73066,6.51232 9.21835,0 15.73066,-6.51232 6.54206,-6.54205 6.54206,-15.73066 0,-9.21835 -6.54206,-15.73066 -6.51231,-6.54206 -15.73066,-6.54206 -9.18861,0 -15.73066,6.54206 -6.51232,6.51231 -6.51232,15.73066 z"
       id="path424" />
    <path
       d="m 283.08185,201.71317 q 1.93288,0 3.30076,-1.36789 1.36788,-1.36788 1.36788,-3.3305 v -12.87595 h 18.73406 q 1.93288,0 3.3305,-1.36788 1.36789,-1.36788 1.36789,-3.30076 0,-1.96262 -1.36789,-3.3305 -1.39762,-1.36789 -3.3305,-1.36789 h -18.73406 v -8.2073 h 24.59217 q 1.93288,0 3.3305,-1.36788 1.36789,-1.36788 1.36789,-3.30076 0,-1.93288 -1.36789,-3.30077 -1.39762,-1.39762 -3.3305,-1.39762 h -29.26081 q -1.96262,0 -3.3305,1.39762 -1.36789,1.36789 -1.36789,3.30077 v 35.11892 q 0,1.96262 1.36789,3.3305 1.36788,1.36789 3.3305,1.36789 z"
       id="path426" />
    <path
       d="m 360.90255,166.5645 v 30.45028 q 0,1.96262 1.36788,3.3305 1.39762,1.36789 3.3305,1.36789 1.93288,0 3.30076,-1.36789 1.36789,-1.36788 1.36789,-3.3305 V 166.5645 h 9.96176 q 1.93288,0 3.3305,-1.36788 1.36788,-1.36788 1.36788,-3.30076 0,-1.93288 -1.36788,-3.30077 -1.39762,-1.39762 -3.3305,-1.39762 h -29.26082 q -1.96261,0 -3.3305,1.39762 -1.36788,1.36789 -1.36788,3.30077 0,1.93288 1.36788,3.30076 1.36789,1.36788 3.3305,1.36788 z"
       id="path428" />
    <path
       d="m 424.74698,201.71317 q 1.93288,0 3.3305,-1.36789 1.36789,-1.36788 1.36789,-3.3305 0,-1.93288 -1.36789,-3.30076 -1.39762,-1.36788 -3.3305,-1.36788 h -24.59217 v -8.20731 h 18.73406 q 1.93288,0 3.3305,-1.36788 1.36788,-1.36788 1.36788,-3.30076 0,-1.96262 -1.36788,-3.3305 -1.39762,-1.36789 -3.3305,-1.36789 h -18.73406 v -8.2073 h 24.59217 q 1.93288,0 3.3305,-1.36788 1.36789,-1.36788 1.36789,-3.30076 0,-1.93288 -1.36789,-3.30077 -1.39762,-1.39762 -3.3305,-1.39762 h -29.26081 q -1.96262,0 -3.3305,1.39762 -1.36789,1.36789 -1.36789,3.30077 v 35.11892 q 0,1.96262 1.36789,3.3305 1.36788,1.36789 3.3305,1.36789 z"
       id="path430" />
    <path
       d="m 456.35697,201.71317 q 9.21835,0 15.73066,-6.51232 1.36789,-1.36788 1.36789,-3.3305 0,-1.93288 -1.36789,-3.30076 -1.36788,-1.36789 -3.30076,-1.36789 -1.96262,0 -3.3305,1.36789 -3.77655,3.77655 -9.0994,3.77655 -5.32285,0 -9.0994,-3.77655 -3.77655,-3.77655 -3.77655,-9.0994 0,-5.35259 3.77655,-9.12914 3.77655,-3.77655 9.0994,-3.77655 5.32285,0 9.0994,3.77655 1.36788,1.39762 3.3305,1.39762 1.93288,0 3.30076,-1.39762 1.36789,-1.36788 1.36789,-3.30076 0,-1.93288 -1.36789,-3.30076 -6.51231,-6.54206 -15.73066,-6.54206 -9.21835,0 -15.73066,6.54206 -6.51232,6.48257 -6.51232,15.73066 0,9.21834 6.51232,15.73066 6.51231,6.51232 15.73066,6.51232 z"
       id="path432" />
    <path
       d="m 484.48782,201.71317 q 1.93287,0 3.30076,-1.36789 1.36788,-1.36788 1.36788,-3.3305 v -12.87595 h 19.92352 v 12.87595 q 0,1.96262 1.36789,3.3305 1.36788,1.36789 3.30076,1.36789 1.93288,0 3.3305,-1.36789 1.36788,-1.36788 1.36788,-3.3305 v -35.11892 q 0,-1.93288 -1.36788,-3.30077 -1.39762,-1.39762 -3.3305,-1.39762 -1.93288,0 -3.30076,1.39762 -1.36789,1.36789 -1.36789,3.30077 v 12.87594 h -19.92352 v -12.87594 q 0,-1.93288 -1.36788,-3.30077 -1.36789,-1.39762 -3.30076,-1.39762 -1.96262,0 -3.3305,1.39762 -1.36789,1.36789 -1.36789,3.30077 v 35.11892 q 0,1.96262 1.36789,3.3305 1.36788,1.36789 3.3305,1.36789 z"
       id="path434" />
    <path
       d="m 530.90668,157.49484 q -1.39762,0.50552 -2.23024,1.69499 -0.86236,1.2192 -0.86236,2.70603 v 35.11892 q 0,1.96262 1.36788,3.3305 1.36788,1.36789 3.3305,1.36789 1.93288,0 3.30076,-1.36789 1.36788,-1.36788 1.36788,-3.3305 v -22.1835 l 20.99404,25.1869 q 1.39762,1.69499 3.59813,1.69499 0.83263,0 1.60578,-0.26763 1.39762,-0.50553 2.23024,-1.72473 0.86237,-1.2192 0.86237,-2.70603 v -35.11892 q 0,-1.93288 -1.36789,-3.30077 -1.39762,-1.39762 -3.3305,-1.39762 -1.93288,0 -3.30076,1.39762 -1.36788,1.36789 -1.36788,3.30077 v 22.1835 l -20.99404,-25.1869 q -0.95157,-1.12999 -2.37893,-1.51657 -1.42736,-0.38657 -2.82498,0.11895 z"
       id="path436" />
    <path
       d="m 608.63823,179.47019 q 0,5.32285 -3.77654,9.0994 -3.80629,3.77655 -9.12914,3.77655 -5.32285,0 -9.0994,-3.77655 -3.77655,-3.77655 -3.77655,-9.0994 0,-5.32285 3.77655,-9.0994 3.77655,-3.80629 9.0994,-3.80629 5.32285,0 9.12914,3.80629 3.77654,3.77655 3.77654,9.0994 z m -35.14866,0 q 0,9.18861 6.51232,15.73066 6.54205,6.51232 15.73066,6.51232 9.21835,0 15.73066,-6.51232 6.54205,-6.54205 6.54205,-15.73066 0,-9.21835 -6.54205,-15.73066 -6.51231,-6.54206 -15.73066,-6.54206 -9.18861,0 -15.73066,6.54206 -6.51232,6.51231 -6.51232,15.73066 z"
       id="path438" />
    <path
       d="m 629.72144,201.71317 q -1.96261,0 -3.3305,-1.36789 -1.36788,-1.36788 -1.36788,-3.3305 v -35.11892 q 0,-1.93288 1.36788,-3.30077 1.36789,-1.39762 3.3305,-1.39762 1.93288,0 3.30077,1.39762 1.36788,1.36789 1.36788,3.30077 v 30.45028 h 18.73406 q 1.93288,0 3.3305,1.36788 1.36788,1.36788 1.36788,3.30076 0,1.96262 -1.36788,3.3305 -1.39762,1.36789 -3.3305,1.36789 z"
       id="path440" />
    <path
       d="m 696.48009,179.47019 q 0,5.32285 -3.77655,9.0994 -3.80628,3.77655 -9.12913,3.77655 -5.32285,0 -9.0994,-3.77655 -3.77655,-3.77655 -3.77655,-9.0994 0,-5.32285 3.77655,-9.0994 3.77655,-3.80629 9.0994,-3.80629 5.32285,0 9.12913,3.80629 3.77655,3.77655 3.77655,9.0994 z m -35.14866,0 q 0,9.18861 6.51232,15.73066 6.54205,6.51232 15.73066,6.51232 9.21834,0 15.73066,-6.51232 6.54205,-6.54205 6.54205,-15.73066 0,-9.21835 -6.54205,-15.73066 -6.51232,-6.54206 -15.73066,-6.54206 -9.18861,0 -15.73066,6.54206 -6.51232,6.51231 -6.51232,15.73066 z"
       id="path442" />
    <path
       d="m 732.75873,201.71317 q 9.21835,0 15.73067,-6.51232 6.54205,-6.54205 6.54205,-15.73066 v -0.0297 q 0,-1.96261 -1.4571,-3.36023 -1.42735,-1.36789 -3.4197,-1.30842 h -17.39592 q -1.93288,0 -3.30076,1.36789 -1.36788,1.39762 -1.36788,3.3305 0,1.93288 1.36788,3.30076 1.36788,1.36788 3.30076,1.36788 h 12.01359 q -1.42736,3.65761 -4.66865,5.91759 -3.30076,2.28972 -7.34494,2.28972 -5.32285,0 -9.0994,-3.77655 -3.77654,-3.77655 -3.77654,-9.0994 0,-5.32285 3.77654,-9.0994 3.77655,-3.80629 9.0994,-3.80629 5.35259,0 9.12914,3.77655 1.36788,1.39762 3.30076,1.36788 1.96262,0 3.3305,-1.36788 1.36789,-1.36788 1.36789,-3.30076 0,-1.96262 -1.39762,-3.3305 -6.51232,-6.51232 -15.73067,-6.51232 -9.18861,0 -15.73066,6.54206 -6.51231,6.51231 -6.51231,15.73066 0,9.18861 6.51231,15.73066 6.54205,6.51232 15.73066,6.51232 z"
       id="path444" />
    <path
       d="m 794.96766,157.91115 q -1.63551,-1.01104 -3.53865,-0.59473 -1.90315,0.44605 -2.91419,2.08156 l -10.67544,17.06881 -10.67544,-17.06881 q -1.01105,-1.63551 -2.91419,-2.08156 -1.8734,-0.41631 -3.53865,0.59473 -1.63552,1.04078 -2.08157,2.91419 -0.41631,1.90314 0.59474,3.53865 l 13.94646,22.30246 v 10.34833 q 0,1.96262 1.36789,3.3305 1.36788,1.36789 3.30076,1.36789 1.93288,0 3.3305,-1.36789 1.36788,-1.36788 1.36788,-3.3305 v -10.34833 l 13.91673,-22.30246 q 1.04078,-1.63551 0.59473,-3.53865 -0.44605,-1.87341 -2.08156,-2.91419 z"
       id="path446" />
  </g>
</svg>
);
