import { Suspense, lazy, useMemo } from "react";

import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Preloader from "components/Preloader";

import "./styles/index.css";
import { WritePost } from "pages/WritePost";

const Home = lazy(
  async () => await import("pages/Home").then(({ Home }) => ({ default: Home }))
);

const Blog = lazy(
  async () => await import("pages/Blog").then(({ Blog }) => ({ default: Blog }))
);

const Contacts = lazy(
  async () =>
    await import("pages/Contacts").then(({ Contacts }) => ({
      default: Contacts,
    }))
);

const Post = lazy(
  async () =>
    await import("pages/Post").then(({ Post }) => ({
      default: Post,
    }))
);

const MissingPage = lazy(
  async () =>
    await import("pages/404").then(({ MissingPage }) => ({
      default: MissingPage,
    }))
);

export default function App() {
  const memoizedLoader = useMemo(() => <Preloader />, []);

  return (
    <Suspense fallback={memoizedLoader}>
      <Routes>
        <Route element={<Layout />}>
          <Route element={<Home/>} path="/" />
          <Route element={<Blog/>} path="/blog"/>
          <Route element={<WritePost/>} path="/write-post"/>
          <Route element={<Post/>} path="/post/:slug" />
          <Route element={<Contacts/>} path="/contacts" />
          <Route element={<MissingPage/>} path="*" />
        </Route>
      </Routes>
    </Suspense>
  );
}
