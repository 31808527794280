import { Footer } from "partials/Footer";
import { Navbar } from "partials/Navbar";
import { Outlet } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import "styles/index.css";

const Layout = () => (
  <>
    <Navbar />
    <Outlet />
    <Footer />
    <Toaster
      toastOptions={{
        style: {
          background: "#191919",
          color: "white",
        }
      }}
    />
  </>
);

export default Layout;
