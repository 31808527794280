type ILogoFlatProps = {
  className?: string | undefined;
};

export const LogoFlat = (props: ILogoFlatProps) => (
  <svg
    width="1024"
    height="1024"
    version="1.1"
    viewBox="0 0 1024 1024"
    id="svg5"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
  >
    <title>The Island</title>
    <path
      d="M 513.44836,679.05237 C 412.70471,666.28309 311.18621,673.89251 246.08172,712.50945 115.16455,790.16159 41.878251,1019.4354 209.04448,1023.0722 c 0,0 189.93947,2.0876 591.0977,0 146.91422,-0.7645 113.35799,-94.48234 94.82396,-122.21049 C 878.46383,876.17383 849.75797,813.9738 761.4953,758.96326 712.89191,728.67143 646.02104,704.14018 574.79689,689.19298 Z"
      fill="#57cd38"
      id="path55"
      strokeWidth={4.16588}
    />
    <path
      d="M 513.44836,679.05239 C 412.70471,666.28311 311.18621,673.89253 246.08172,712.50947 115.16454,790.16161 41.878255,1019.4354 209.04448,1023.0722 c 0,0 189.93947,2.0876 591.09774,0 146.91418,-0.7645 113.35796,-94.48232 94.82393,-122.21047 C 878.46382,876.17385 849.75794,813.97382 761.49529,758.96328 712.89188,728.67145 646.02106,704.1402 574.79689,689.193 Z"
      fill="#57cd38"
      id="path3"
      strokeWidth={4.16588}
    />
    <path
      d="m 481.85108,317.0416 c 0,0 56.84424,92.22285 61.69035,162.4681 3.1358,45.44938 -30.34072,197.34642 -30.34072,197.34642 l 61.36516,10.13798 c 0,0 24.51739,-165.40311 18.39721,-214.58964 -9.70139,-77.96301 -67.82387,-165.19132 -67.82387,-165.19132 z"
      fill="#57cd38"
      stroke="#57cd38"
      strokeWidth={20.8286}
      id="path2"
    />
    <path
      d="m 599.90206,28.459557 c -21.524,13.712854 -53.18715,35.800513 -84.20325,82.575653 -21.05707,31.75653 -25.94987,52.09652 -28.06275,58.2734 -2.12855,-10.71347 -4.72737,-43.63167 -49.99865,-82.250471 -59.07553,-50.397216 -136.8134,-50.73074 -136.8134,-50.73074 0,0 48.72959,31.339626 71.06738,60.359588 33.77102,43.870143 46.48498,59.312333 50.76577,78.643393 -16.05653,-16.49345 -20.0148,-25.40621 -76.80568,-33.24571 -56.79089,-7.83949 -107.98689,17.55006 -107.98689,17.55006 0,0 53.08375,15.64046 83.81304,27.27396 6.38782,2.41806 18.07535,5.97743 35.06341,19.29606 -5.47496,0.0375 -13.65148,-1.60377 -82.66903,25.11272 -69.01788,26.71698 -112.95139,96.87385 -112.95139,96.87385 0,0 67.34026,-28.97661 102.62046,-36.10069 40.46653,-8.17185 62.99777,-6.2214 81.43501,-3.6806 -18.94753,10.94626 -39.7828,27.43905 -59.85097,52.66684 -60.4413,75.98187 -60.58304,172.73231 -60.58304,172.73231 0,0 42.67945,-55.44677 124.54469,-130.09287 19.32775,-17.62343 31.38466,-29.15504 39.16078,-37.02956 -1.09591,8.36079 -2.88115,23.88703 -6.16736,56.93929 -7.16795,72.09465 12.99377,102.31531 12.99377,102.31531 0,0 13.41452,-66.79328 36.77607,-119.03489 23.36173,-52.2416 71.44595,-74.77618 71.44595,-74.77618 0,0 31.39799,-16.66271 105.17027,14.42894 34.88499,14.70276 111.92414,66.57148 111.92414,66.57148 0,0 5.53751,-49.35495 -34.24629,-91.95937 -43.04134,-38.1602 -79.60562,-51.14264 -109.88464,-55.28167 29.24009,-12.74648 64.35688,-14.52132 104.36648,-14.79682 54.9815,-0.37866 176.18429,9.8775 176.18429,9.8775 0,0 -49.04144,-56.25725 -119.67024,-71.05906 -59.2206,-12.41094 -76.08193,-4.81208 -100.38753,4.00313 16.36137,-26.77701 45.03081,-46.12643 70.64882,-64.10673 25.61801,-17.980311 78.57336,-41.995734 78.57336,-41.995734 0,0 -88.09048,-17.551733 -160.70209,24.050441 -72.61162,41.603853 -83.73799,88.353973 -91.16058,99.255213 0.44846,-11.06917 17.67847,-57.46626 28.08276,-84.89198 z"
      fill="#57cd38"
      stroke="#57cd38"
      strokeWidth={20.8286}
      id="path5"
    />
    <g id="g8" />
  </svg>
);
